export default
{
	"fps_limit": 28,
	"particles": {
		"number": {
			"value": 600,
			"density": {
				"enable": false
			}
		},
		"line_linked": {
			"enable": true,
			"distance": 20,
			"opacity": 0.4
		},
		"move": {
			"speed": 0.5
		},
		"opacity": {
			"anim": {
				"enable": true,
				"opacity_min": 0.05,
				"speed": 2,
				"sync": false
			},
			"value": 0.4
		}
	},
	"polygon": {
		"enable": true,
		"scale": 0.5,
		"type": "inline",
		"move": {
			"radius": 1,
		},
		"url": "/pfp.svg",
		"inline": {
			"arrangement": "equidistant"
		},
		"draw": {
			"enable": false,
			"stroke": {
				"color": "rgba(255, 255, 255, .2)"
			}
		}
	},
	"retina_detect": false,
	"interactivity": {
		"detect_on": "canvas",
		"events": {
			"onhover": {
				"enable": false,
				"mode": "bubble"
			}
		},
		"modes": {
			"bubble": {
				"size": 6,
				"distance": 40
			}
		}
	}
};