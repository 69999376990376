import React from 'react';
import Particles from 'react-particles-js';
import './App.css';
import './css/style.css';
import particles_config from './etc/particles.js';
import particles_bg_config from './etc/particles_bg.js'

const App: React.FC = () => 
{
	return (
		<div className="App">
			<header className="App-header">
				{/* <img src={logo} className="App-logo" alt="logo" /> */}
				<Particles
					className="App-logo"
					// @ts-ignore TS doesn't like my config for some reason
					params={particles_config}
				/>
				<p>
					Currently under active construction :P
				</p>
				<a
					className="App-link"
					href="javascript:void(0);"
					onClick={(e) => { e.preventDefault(); window.history.back(); }}
					target="_blank"
					rel="noopener noreferrer"
				>
					Go Back
				</a>
			</header>
			<Particles
				className="background"
				// @ts-ignore same thing again
				params={particles_bg_config} />
		</div>
	);
}

export default App;
